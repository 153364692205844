import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import ArrowRight from '../img/inline/arrow-right.svg'
import smoothScroll from './utils/smoothScroll'

const BackToTopButton = ({ url }) => {
    return (
        <button
            className={cx(
                'inline-block bg-gray-900 bg-opacity-50 text-white p-12 rounded-6',
                'fixed bottom-0 right-0 ml-auto mr-40 mb-16 group'
            )}
            onClick={() => smoothScroll(url)}
        >
            <p className={cx('flex items-center')}>
                <span
                    className={cx(
                        'border border-white rounded-full px-8 py-12'
                    )}
                >
                    <ArrowRight
                        className={cx(
                            'transform -rotate-90 group-hover:-translate-y-4 duration-200'
                        )}
                    />
                </span>
                <span className={cx('text-12 ml-10 uppercase leading-snug')}>
                    Back to <br /> the Top
                </span>
            </p>
        </button>
    )
}

BackToTopButton.propTypes = {
    url: PropTypes.string.isRequired,
}

export default BackToTopButton
