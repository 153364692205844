import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import cx from 'classnames'

import Layout from '../components/Layout'
import HomepageAnimation from '../components/HomepageAnimation'
import Image from '../components/Image'
import HomepageArrow from '../img/inline/homepage-arrow.svg'
import HeroBrain from '../img/inline/hero_dreem.jpg'
import LogoGrid from '../components/LogoGrid'
import Partnerships from '../components/Partnerships'
import BackToTopButton from '../components/BackToTopButton'

export const IndexPageTemplate = ({ homepageContent, preview = false }) => {
    const {
        heading,
        introduction,
        keyframeText,
        whatWeDoHeading,
        whatWeDoItems,
        platformHeading,
        platformItems,
        howWeCreateImpactHeading,
        howWeCreateImpactItems,
        whereWeCanHelpHeading,
        whereWeCanHelpItems,
        partnershipsHeading,
        partnershipsText,
        partnershipsButtonLabel,
        partnershipsButtonUrl,
        logoGrid,
    } = homepageContent

    return (
        <>
            <section
                id="top"
                className={cx(
                    'bg-purple-700 text-white min-h-650 max-h-650 md:max-h-760 h-screen relative overflow-hidden'
                )}
            >
                <div
                    className={cx(
                        'max-w-1264 mx-auto px-24 xl:px-32 flex sm:items-center h-full relative z-1'
                    )}
                >
                    <div
                        style={{
                            marginTop: '80px',
                        }}
                    >
                        <div className={cx('max-w-600 sm-d:mt-80')}>
                            <h1 className={cx('text-40 md:text-64')}>
                                {heading}
                            </h1>
                            <p className={cx('text-24 mt-20')}>
                                {introduction}
                            </p>
                            <a
                                href="/products-services"
                                className={cx(
                                    'inline-block text-16 font-bold leading-none px-20 py-16 mt-40',
                                    'bg-blue-500 rounded-6 duration-200 hover:bg-blue-700',
                                    'block bg-blue-500 text-white rounded-6 text-16 font-bold px-20 py-12 duration-200 hover:bg-blue-700 whitespace-no-wrap'
                                )}
                            >
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>

                <img
                    src={HeroBrain}
                    className={cx(
                        'absolute z-0 text-transparent right-0 top-0'
                    )}
                />
            </section>

            {preview ? (
                'Animation will display here (not in preview mode)'
            ) : (
                <HomepageAnimation keyframeText={keyframeText} />
            )}

            <section id="what-we-do" className={cx('bg-purple-700 text-white')}>
                <div className={cx('max-w-1264 mx-auto px-8 py-60 lg:py-100')}>
                    <h2 className={cx('hd-jb text-center mb-60')}>
                        {whatWeDoHeading}
                    </h2>

                    <div
                        className={cx('border-2 border-purple-500 rounded-24')}
                    >
                        <ul
                            className={cx(
                                'flex flex-wrap pt-60 pb-20 text-center'
                            )}
                        >
                            {whatWeDoItems.map((item) => (
                                <li
                                    className={cx(
                                        'w-full px-32 mb-60 md:w-1/3'
                                    )}
                                    key={item.title}
                                >
                                    <Image
                                        image={item.icon}
                                        className={cx('mx-auto mb-24')}
                                    />
                                    <div>
                                        <strong
                                            className={cx('block hd-md mb-12')}
                                        >
                                            {item.title}
                                        </strong>
                                        <p>{item.description}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <div
                            className={cx(
                                'border-t-2 border-purple-500 flex flex-wrap justify-center'
                            )}
                        >
                            <h3
                                className={cx(
                                    'bg-white text-purple-700 hd-xs py-8 px-16 text-center rounded-16 transform -translate-y-1/2 mb-40 mx-12'
                                )}
                            >
                                {platformHeading}
                            </h3>
                            <ul className={cx('flex flex-wrap w-full')}>
                                {platformItems.map((item) => (
                                    <li
                                        className={cx(
                                            'flex items-center w-full px-32 mb-40 lg:w-1/3'
                                        )}
                                        key={item.title}
                                    >
                                        <Image
                                            image={item.icon}
                                            className={cx('mr-20')}
                                        />
                                        <p className={cx('hd-sm')}>
                                            {item.title}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div
                    className={cx(
                        'max-w-1264 mx-auto py-60 lg:pt-100 lg:pb-20'
                    )}
                >
                    <h2 className={cx('hd-jb text-center mb-80 px-8')}>
                        {howWeCreateImpactHeading}
                    </h2>

                    <ul
                        className={cx(
                            'md:flex md:flex-wrap md:pb-20 text-center'
                        )}
                    >
                        {howWeCreateImpactItems.map((item, index) => (
                            <li
                                className={cx(
                                    'w-full px-40 py-20 mb-40 md:w-1/3 border-dotted border-gray-300',
                                    {
                                        'md:border-r xl:relative':
                                            (index + 1) % 3 !== 0,
                                        'md-d:border-t md-d:pt-40': index > 0,
                                    }
                                )}
                                key={item.title}
                            >
                                <Image
                                    image={item.icon}
                                    className={cx('mx-auto mb-48 text-white')}
                                />
                                <div>
                                    <strong className={cx('block hd-md mb-12')}>
                                        {item.title}
                                    </strong>
                                    <p>{item.description}</p>
                                </div>

                                {(index + 1) % 3 !== 0 && (
                                    <div
                                        className={cx(
                                            'xl-d:hidden absolute right-0 top-52 transform translate-x-1/2'
                                        )}
                                    >
                                        <HomepageArrow />
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </section>

            <div className={cx('max-w-1264 mx-auto px-32')}>
                <div className={cx('border-t border-gray-100')} />
            </div>

            <section>
                <div className={cx('max-w-1264 mx-auto py-60 lg:py-100')}>
                    <h2 className="hd-jb text-center mb-80 px-8">
                        {whereWeCanHelpHeading}
                    </h2>

                    <ul
                        className={cx(
                            'w-full text-center grid md:grid-cols-2 lg:grid-cols-4 gap-20 px-20 mb-40'
                        )}
                    >
                        {whereWeCanHelpItems.map((item) => (
                            <li className={cx('w-full group')} key={item.title}>
                                <a href={item.url} className={cx('block')}>
                                    <div
                                        className={cx(
                                            'bg-gray-100 h-full w-full rounded-24 p-40 lg:p-80',
                                            'group-hover:gradient-purple-sky group-hover:animate-gradient-background group-hover:bg-gradient-animation',
                                            'group-focus:gradient-purple-sky group-focus:animate-gradient-background group-focus:bg-gradient-animation'
                                        )}
                                    >
                                        <Image
                                            image={item.icon}
                                            className={cx('w-90 mx-auto mb-20')}
                                        />
                                        <p
                                            className={cx(
                                                'text-24 font-medium group-hover:text-white group-focus:text-white',
                                                'transition ease-out duration-400'
                                            )}
                                        >
                                            {item.title}
                                        </p>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>

            {logoGrid && (
                <LogoGrid
                    logoGrid={logoGrid}
                    className="mt-24 mb-60 px-32 mx-auto"
                />
            )}

            {partnershipsHeading &&
                partnershipsText &&
                partnershipsButtonUrl &&
                partnershipsButtonLabel && (
                    <Partnerships
                        partnershipsHeading={partnershipsHeading}
                        partnershipsText={partnershipsText}
                        partnershipsButtonUrl={partnershipsButtonUrl}
                        partnershipsButtonLabel={partnershipsButtonLabel}
                        page="Homepage"
                    />
                )}

            <BackToTopButton url="top" />
        </>
    )
}

IndexPageTemplate.propTypes = {
    homepageContent: PropTypes.shape({
        heading: PropTypes.string,
        introduction: PropTypes.string,
        keyframeText: PropTypes.array,
        whatWeDoHeading: PropTypes.string,
        whatWeDoItems: PropTypes.array,
        platformHeading: PropTypes.string,
        platformItems: PropTypes.array,
        howWeCreateImpactHeading: PropTypes.string,
        howWeCreateImpactItems: PropTypes.array,
        whereWeCanHelpHeading: PropTypes.string,
        whereWeCanHelpItems: PropTypes.array,
        partnershipsHeading: PropTypes.string,
        partnershipsText: PropTypes.string,
        partnershipsButtonLabel: PropTypes.string,
        partnershipsButtonUrl: PropTypes.string,
        logoGrid: PropTypes.shape({
            heading: PropTypes.string,
            logoGridImages: PropTypes.array,
        }),
    }),
    preview: PropTypes.bool,
}

const IndexPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark

    return (
        <Layout title={frontmatter.title}>
            <IndexPageTemplate homepageContent={frontmatter} />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage

export const pageQuery = graphql`
    query IndexPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                title
                heading
                introduction
                keyframeText {
                    text
                    start
                    stop
                }
                whatWeDoHeading
                whatWeDoItems {
                    icon {
                        extension
                        publicURL
                    }
                    title
                    description
                }
                platformHeading
                platformItems {
                    icon {
                        extension
                        publicURL
                    }
                    title
                }
                howWeCreateImpactHeading
                howWeCreateImpactItems {
                    icon {
                        extension
                        publicURL
                    }
                    title
                    description
                }
                whereWeCanHelpHeading
                whereWeCanHelpItems {
                    icon {
                        extension
                        publicURL
                    }
                    title
                    url
                }
                partnershipsHeading
                partnershipsText
                partnershipsButtonLabel
                partnershipsButtonUrl
                logoGrid {
                    heading
                    logoGridImages {
                        title
                        logo {
                            childImageSharp {
                                fluid(maxWidth: 300, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
