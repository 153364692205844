import React, { useEffect, useRef, useState } from 'react'
import '@lottiefiles/lottie-player'
import { create } from '@lottiefiles/lottie-interactivity'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { CSSTransitionGroup } from 'react-transition-group'

const HomepageAnimation = ({ keyframeText }) => {
    const [lottieText, setLottieText] = useState(null)
    const [currentKeyframe, setCurrentKeyframe] = useState(null)
    const lottieRef = useRef(null)
    const totalFrames = 1110
    const visibilityStartPercentage = 0.1

    const swapText = (e) => {
        const { frame } = e.detail

        // Double emmitting events with this as one of the values each time :shrug:
        if (frame === 0.001) return

        const newKeyframe = keyframeText.find(
            ({ start, stop }) => frame >= start && frame <= stop
        )

        if (newKeyframe && newKeyframe !== currentKeyframe) {
            setCurrentKeyframe(newKeyframe)
            setLottieText(newKeyframe.text)
        } else if (!newKeyframe) {
            setCurrentKeyframe(null)
            setLottieText(null)
        }
    }

    const handleScroll = () => {
        const animationText = document.getElementById('animation-text')

        animationText?.classList?.add('opacity-100')
        window.removeEventListener('scroll', handleScroll)
    }

    useEffect(() => {
        create({
            mode: 'scroll',
            player: '#lottie-el',
            container: '#lottie-container',
            actions: [
                {
                    visibility: [0, visibilityStartPercentage],
                    type: 'stop',
                    frames: [0],
                },
                {
                    visibility: [visibilityStartPercentage, 1],
                    type: 'seek',
                    frames: [0, totalFrames],
                },
            ],
        })

        lottieRef.current.addEventListener('frame', swapText)
        window.addEventListener('scroll', handleScroll)

        // We only want this effect to run once, so passing an empty array is fine.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        // TODO update src?
        <section className={cx('min-h-screen')}>
            <div className={cx('h-animation relative')} id="lottie-container">
                <div
                    className={cx(
                        'min-h-screen h-screen sticky top-0 left-0 right-0 overflow-hidden'
                    )}
                >
                    <lottie-player
                        ref={lottieRef}
                        class="lottie-player h-screen"
                        id="lottie-el"
                        src="https://assets5.lottiefiles.com/private_files/lf30_LCvZZF.json"
                    />

                    <CSSTransitionGroup
                        transitionName="animation-text"
                        transitionEnterTimeout={300}
                        transitionLeaveTimeout={100}
                        component="div"
                        id="animation-text"
                        className={cx('duration-200 opacity-0')}
                    >
                        <div
                            className={cx(
                                'absolute top-20 left-0 w-full text-28 leading-relaxed p-20 text-center'
                            )}
                            key={lottieText}
                        >
                            <span className={cx('bg-white')}>{lottieText}</span>
                        </div>
                    </CSSTransitionGroup>
                </div>
            </div>
        </section>
    )
}

HomepageAnimation.propTypes = {
    keyframeText: PropTypes.array.isRequired,
}

export default HomepageAnimation
